import classes from "./PressRelease.module.scss";
import { ReactComponent as Arrow } from "../../assets/icons/arrow_with_tail.svg";
import pressReleaseLinks from "../Routes/pressRelease/pressReleaseLinks";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

type PressReleases = {
  date: string;
  img: string;
  title?: string;
  desc: string;
  link: string;
  imgAlt: string;
}[];

const pressReleases: PressReleases = [
  {
    date: "7th Feb' 25",
    img: "https://se-website-image.s3.ap-south-1.amazonaws.com/MOU1.png",
    desc: "Sustainability Economics and Gujarat Power Corporation Limited Sign MoU to Accelerate the Convergence of AI and Clean Energy",
    link: pressReleaseLinks.gpclmou,
    imgAlt: "GPCLMOU",
  },
  {
    date: "14th Jan' 25",
    img: "https://se-website-image.s3.ap-south-1.amazonaws.com/SE-AIGCC.png",
    desc: "Sustainability Economics Joins AIGCC to Drive Net-Zero Transitions in Asia ",
    link: pressReleaseLinks.aigcc,
    imgAlt: "AIGCC",
  },
  {
    date: "16th Dec' 24",
    img: "https://se-website-image.s3.ap-south-1.amazonaws.com/3+2.png",
    desc: "Sustainability Economics Signs Landmark MoU with INZET as Bali’s Clean Energy and AI Cloud Data Center Advisor ",
    link: pressReleaseLinks.balimou,
    imgAlt: "BALIMOU",
  },
  {
    date: "2nd Dec' 24",
    img: "https://se-website-image.s3.ap-south-1.amazonaws.com/9-1.png",
    desc: "Scott Eaton Joins Sustainability Economics’ Advisory Board ",
    link: pressReleaseLinks.scotteaton,
    imgAlt: "SCOTTEATON",
  },
  {
    date: "25th Sept' 24",
    img: "https://se-website-image.s3.ap-south-1.amazonaws.com/Image+(7)+1.jpeg",
    desc: "Sustainability Economics to Host Full-Day Workshop at Climate Week NYC 2024",
    link: pressReleaseLinks.climateweek,
    imgAlt: "CLIMATEWEEK",
  },
  {
    date: "26th July’ 24",
    img: "https://se-website-image.s3.ap-south-1.amazonaws.com/press-releases/sucofindo.png",
    desc: "Sustainability Economics partners with PT SUCOFINDO, a state-owned company of Indonesia, to accelerate the transition of coal-fired power plants to clean energy sources in Indonesia",
    link: pressReleaseLinks.sucofindo,
    imgAlt: "SUCOFINDO",
  },
  {
    date: "17th April’24",
    img: "https://se-website-image.s3.ap-south-1.amazonaws.com/press-releases/aci.png",
    desc: "Sustainability Economics works with Asia Carbon Institute, of the TRACTION group, to accelerate Phase-Out of Coal-Fired Power Plants in Asia",
    link: pressReleaseLinks.aci,
    imgAlt: "Asia Carbon Institute",
  },
];

function PressRelease() {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Press Release";
  }, []);

  return (
    <div className={classes["container"]}>
      <div className={classes.header}>
        <img
          className={classes["header-img"]}
          src="https://se-website-image.s3.ap-south-1.amazonaws.com/press-releases/header-img.jpeg"
          alt="Press Release"
        />

        <div className={classes.title}>Press Release</div>
      </div>

      {pressReleases && pressReleases.length > 0 && (
        <div className={classes["releases-container"]}>
          <div className={classes["releases-title"]}>Recent</div>
          <div className={classes["separator"]}></div>
          <div className={classes["releases"]}>
            {pressReleases.map((event, index) => (
              <div
                key={index}
                className={classes["ele-separator-container"]}
                // onClick={() => navigate(event.link)}
                onClick={() => window.open(event.link)}
              >
                <div className={classes["ele-container"]}>
                  <div className={classes["date"]}>{event.date}</div>
                  <div className={classes["release-img"]}>
                    <img
                      src={event.img}
                      height="100%"
                      width="100%"
                      alt={event.imgAlt}
                    ></img>
                  </div>
                  <div className={classes["info"]}>
                    <div className={classes["info-date"]}>{event.date}</div>

                    <div>
                      {event.title ? (
                        <p className={classes["release-title"]}>
                          {event.title}
                        </p>
                      ) : null}

                      <p className={classes["desc"]}>{event.desc}</p>
                    </div>
                    <div className={classes["link-container"]}>
                      <Arrow />
                    </div>
                  </div>
                </div>
                <div className={classes["separator"]}></div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default PressRelease;
